.settings-toggle {
  margin-bottom: 10px;
}

.myalerts {
  margin: 0 20px;
}

.alert-info {
  background-color: #344675;
  border-color: #344675;
}

.main-panel .public-content {
  padding: 80px 30px 30px 30px;
}

.public-panel .footer {
  padding: 24px 0;
}

.public-panel .navbar .navbar-brand {
  margin-left: 0;
  position: relative;
}

.public-wrapper .navbar-menu a {
  color: white;
}

@media only screen and (max-width: 991px) {
  .public-wrapper .small-hidden {
    display: none;
  }
}

@media only screen and (max-width: 501px) {
  .public-wrapper .small-small-hidden {
    display: none;
  }
  .public-wrapper app-public-navbar .navbar {
    padding: 25px 30px 10px 15px;
  }
}

@media only screen and (min-width: 992px) {
  .public-wrapper .sidebar {
    display: none;
  }
  .public-wrapper .navbar-menu {
    padding-top: 11px;
  }
}

.login-row {
  margin: 5% 0 0 0;
}

.login-content .card-header {
  margin-top: 15px;
}

.login-content .card-footer {
  margin-bottom: 15px;
}

.btn-not-primary {
  opacity: 50%;
}

.btn-white {
  color: #ffffff !important;
}

.col-tn-1 {
  width: 10%;
}

.col-tn-2 {
  width: 20%;
}

.col-tn-3 {
  width: 30%;
}

.col-tn-4 {
  width: 40%;
}

.col-tn-5 {
  width: 50%;
}

.col-tn-6 {
  width: 60%;
}

.col-tn-7 {
  width: 70%;
}

.col-tn-8 {
  width: 80%;
}

.col-tn-9 {
  width: 90%;
}

.col-tn-10 {
  width: 100%;
}

.table-padding {
  padding: 8px 0;
}

.table-margin {
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.card .card-body .table-margin {
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.no-margin {
  margin: 0;
}

.no-padding-left {
  padding-left: 0;
}

.logo-mhp {
  position: relative;
  top: -4px;
  width: 40px;
}

.logo-mhp img {
  border-radius: 0;
  width: 100%;
}

.simple-text-mhp {
  margin-right: 10px;
  margin-left: 90px;
}

.icon-in-table {
  width: 40px;
}

.td-actions.text-right.icon-in-table {
  padding: 12px 3px;
}

.td-two-actions {
  width: 100px;
}

.td-three-actions {
  width: 140px;
}

.progress-container.progress-sm .progress .progress-value {
  position: absolute;
  top: -3px;
  left: -27px;
  color: #fff;
  font-size: .62475rem;
}

.progress-container {
  position: relative;
}

.table .progress-td {
  width: 85px;
  padding-left: 35px;
}

.table .date-td {
  width: 90px;
}

.table .status-td {
  width: 110px;
}

.table .task-status-td {
  width: 85px;
}

.table .responsible-td {
  width: 100px;
}

.progress-sm .mat-progress-bar {
  height: 0.5rem;
}

.progress-dashboard {
  margin-top: 10px;
}

.mat-progress-bar-buffer {
  background-color: black;
}

.mat-progress-bar .mat-progress-bar-fill::after {
  border-radius: .875rem;
  box-shadow: none;
  background: #344675;
}

.card-auto-height {
  height: auto;
}

.card-auto-height .card-body {
  padding-bottom: 25px;
}

app-golives-table {
  height: 60vh;
}

.card .icon-golivedetail {
  font: normal normal normal 2em/1 'Nucleo';
}

.card .title-golivedetail {
  margin-top: 20px;
}

.card .description-golivedetail {
  margin-bottom: 20px;
}

.card-golivedetail .card-body {
  min-height: 180px;
}

.detail-padding {
  padding: 10px;
}

.row .card-height-auto {
  height: auto;
}

.persons {
  margin-right: 7px;
}

.card label {
  color: rgba(255, 255, 255, 0.4) !important;
}

.card-tasks .table-responsive {
  overflow: auto;
  padding-bottom: 0px;
}

.car-tasks {
  height: 700px !important;
}

.card-dashboard-messages {
  height: 248px;
}

.alert {
  padding: 0.5rem 1rem;
  font-weight: 100;
  margin-bottom: 0.5rem;
}

.alert span[data-notify=icon] {
  font-size: 18px;
}

.alert.alert-with-icon {
  padding-left: 55px;
}

.title {
  font-weight: 500;
}

.alert-danger {
  color: white;
  background-color: #f5365c;
  border-color: #f5365c;
}

.overflowauto {
  overflow: auto;
}

.card-tasks .card-body .icon-small {
  font-size: 0.9rem;
}

.form-check input[type=checkbox]:checked + .form-check-sign::before, .form-check input[type=checkbox]:checked + .form-check-sign::before {
  background-color: #5e72e4;
}

.mat-select {
  background-color: #344675;
  border-left: 9px solid #344675;
  border-right: 5px solid #344675;
  border-top: 5px solid #344675;
  border-bottom: 5px solid #344675;
  border-radius: 25px;
}

dialog-create-golive .mat-select, dialog-create-rollbacktask .mat-select, dialog-create-runbooktask .mat-select {
  background-color: inherit;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-radius: 0;
}

.mat-select-done {
  border-left: 9px solid #00f2c3 !important;
  border-right: 5px solid #00f2c3 !important;
  border-top: 5px solid #00f2c3 !important;
  border-bottom: 5px solid #00f2c3 !important;
  background-color: #00f2c3 !important;
  border-radius: 25px;
}

.mat-select-done .mat-select-value-text {
  color: black;
  font-weight: 500;
}

.mat-select-done .mat-select-arrow {
  color: black;
}

app-golives-table {
  width: 100%;
}

app-golives-table .card-body {
  height: 59vh;
  overflow: auto;
}

.mat-dialog-container {
  margin-bottom: 20%;
  background-color: #1a1e34;;
}

.form-in-dialog mat-form-field {
  width: 100%;
}

.distance {
  margin: 15px 0;
}

.distance-top {
  margin: 5px 0 0 0;
}

.distance-top-double {
  margin: 15px 0 0 0;
}

.avatar-container {
  width: 100px;
  height: 100px;
  float: left;
  margin: 10px;
}

.avatar-selected {
  border: 5px solid #1d8cf8;
  border-radius: 10px;
}

.dialog-avatar {
  width: 42vw;
  max-width: 560px;
}

.card .avatar.small {
  margin: 5px;
}

.margin-bottom-minus {
  margin-bottom: -14px;
}

.border-top-down-grey {
  border-bottom: 2px solid ;
  border-top: 2px solid ;
}

.not-hover:hover {
  color: rgb(154, 154, 154) !important;
}

.distance-bottom {
  padding-bottom: 20px;
}


.status-100 {
  width: 100px;
}

.mytable > tbody > tr > th {
  border-bottom-width: 1px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  border: 0;
  color: rgba(255, 255, 255, 0.7);
}

.position-absolute {
  position: absolute;
}

.opened-task {
  height: 100px;
}

.opened-task .td-padding{
  padding-bottom: 50px !important;
}

.task-description {
  top: 25px;
}

.width-100 {
  width: 100%;
}

.runbook-task-dashboard-table tbody + tbody{
  border-top: none !important;
}

.opened-golive {
  heigth: 380px;
}

.opened-golive .td-padding {
  padding-top: 15px !important;
  padding-bottom: 330px !important;
}

.opened-golive-content {
  position: relative;
  top: -325px;
}

.opened-golive-wrapper {
  left: 8vw;
}

.td-status-m {
  width: 125px;
}

.td-status-100 {
  width: 100px !important;
}

.rotate {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    } to {
        -ms-transform: rotate(360deg);
      }
  }
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    } to {
        -moz-transform: rotate(360deg);
      }
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    } to {
        -webkit-transform: rotate(360deg);
      }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
      }
  }
}

.icon-nav{
  position: relative;
  top: 3px;
  font-size: 15px !important;
}

@media screen and (min-width: 992px) {
  .navbar .caret {
    top: 45% !important;
  }
}

.mat-option-text, personss {
  font-size: 13px !important;
}

.mat-option {
  line-height: 40px !important;
  height: 40px !important;
}

.form-in-dialog label {
  position: absolute;
  font-size: 13px;
}

.w20px {
  width: 20px;
}

.w40px {
  width: 40px;
}

.margin10pxtop {
  margin-top: 10px;
}

.footer .copyright {
  color: #757575;
}

.dropdown-menu .dropdown-item {
  color: #9A9A9A !important;
}

.table-team .table-full-width {
  height: 562px !important;
  max-height: none;
}

.card-team {
  height: 630px !important;
}

.table-team {
  height: 100%;
}

.card-dashboard-messages .container {
  max-width: none;
}

.books-table-code{
   width: 10%;
 }

.books-table-title{
  width: 60%;
}

.books-table-version{
  width: 10%;
}

.bookurl-table-version{
  width: 10% !important;
}

.bookurl-table-code{
  width: 15% !important;
}

.bookurl-table-title{
  width: 20% !important;
}

.mat-dialog-book {
  width: 500px;
}

.card-book-detail .card-body {
  min-height: auto !important;
}

.card-book-detail .author {
  margin-top: -5px !important;
}

.mat-button-toggle-checked {
  background-color: #344675;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {

}

.mat-button-toggle-appearance-standard {
  color: #ffffffa3;
  background: rgb(0 0 0 / 15%);
}

.mat-button-toggle-checked {
  background-color: #344675 !important;
}

//.card-tasks .card-body .mat-button-toggle-checked i {
//  color: $opacity-8;
//}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 34px !important;
}

//input.mat-input-element {
//  color: $opacity-8;
//}

//.card.card-tasks.card-laws {
//  min-height: 300px !important;
//}

.card-laws .card-body {
  color: $opacity-8;
}

.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 0px;
}

.scrollable {
  overflow-y: scroll;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: whitesmoke #27293d ;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #27293d;
}

*::-webkit-scrollbar-thumb {
  background-color: whitesmoke;
  border-radius: 20px;
  border: 3px solid whitesmoke;
}

.card-side-over {
  background: #3a3a3a !important;
  padding-bottom: 10px !important;
}

.card {
  margin-bottom: 10px !important;
}

.button_werkzeug {
  color: #ffffffa3;
  background: rgb(0 0 0 / 15%);
  border: solid 1px rgba(255,255,255,.12);
  cursor: pointer;
  line-height: 34px !important;
  padding: 0 12px;
}

.button_werkzeug:hover{
  background: rgb(0 0 0 / 1%);
}

.button_werkzeug:focus {
  outline: none;
}

.ohne {
  background-color: #27293d;
  color: rgba(255, 255, 255, 0.8);
}
